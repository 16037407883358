// home.js
import "magnific-popup";

import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
getCurrentBrowserFingerPrint().then((fingerprint) => {
  console.log("Fingerprint:", fingerprint);

  let cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("visitorID"));
  let currentVisitorID = cookie ? cookie.split("=")[1] : null;

  if (!currentVisitorID || currentVisitorID !== fingerprint) {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    let expires = "; expires=" + date.toUTCString();

    document.cookie = `visitorID=${fingerprint}; path=/;${expires}`;
  }
});

const imagesLoaded = require("imagesloaded");
imagesLoaded.makeJQueryPlugin(jQuery);

const jQueryBridget = require("jquery-bridget");
const Isotope = require("isotope-layout");
jQueryBridget("isotope", Isotope, jQuery);

const jValidate = require("jquery-validation");
jQueryBridget("validate", jValidate, jQuery);

require("../src/home/bootstrap.min.js");

require("../src/home/owl.carousel.min.js");

require("../src/home/jquery.easing.min.js");
require("../src/home/jquery.appear.js");
require("../src/home/menu.js");

require("../src/home/request-form.js");
require("../src/home/jquery.ajaxchimp.min.js");
require("../src/home/popper.min.js");
require("../src/home/lunar.js");

const wowjs = require("../src/home/wow.js");
global.WOW = wowjs;
window.WOW = wowjs;

require("../src/home/cookies-message.js");

// Custom Script
require("../src/home/custom.js");

// require("../src/home/modernizr.custom.js");
